<template>
  <el-dialog
    fullscreen
    :visible.sync="dialogVisible"
    width="80%"
    custom-class="update-history-dialog"
    v-on="$listeners">
    <base-title
      slot="title"
      :text="`${$t('updateHistory')} [${company.companyName}]`">
    </base-title>
    <div class="content-container">
      <el-table
       class="left-table"
        style="margin-right: 20px"
        v-no-border
        :data="histories"
        stripe
        row-key="id"
        highlight-current-row
        size="small"
        :current-row-key="currentRowKey"
        @row-click="rowClickHandler">
        <el-table-column
          type="index"
          :label="$t('serialNum')"
          width="50">
        </el-table-column>

        <el-table-column
          prop="updateTime"
          align="center"
          :label="$t('updateTime')">
        </el-table-column>

        <el-table-column
          align="center"
          :label="$t('updatedBy')">
          <template slot-scope="scope">
            <span>{{ scope.row.modifyUser | formatModifyUser }}</span>
          </template>
        </el-table-column>

        <el-table-column
          :label="$t('auditStatus')"
          align="center">
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.status===1?'warning':scope.row.status===2?'danger':'success'"
              size="mini">
              {{ formatApprove(scope.row.status) }}
            </el-tag>
            <span></span>
          </template>
        </el-table-column>

        <el-table-column
          prop="comment"
          align="center"
          :label="$t('auditInstruction')">
        </el-table-column>
      </el-table>
      <div
        class="detail"
        v-show="histories.length">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="old-detail">
              <p>{{ $t('preUpdateInfo') }}</p>
              <p
                v-for="item in beforeDetail"
                :key="item.key"
                :class="{active:item.isDifferent}">
                <template v-if="fileFields.includes(item.key)">
                  <span>{{ $t(item.key) }}：</span>
                  <span
                    style="display: block"
                    v-for="(item1,index) in item.data"
                    :key="item.key + index">
                    {{ index + 1 }}.{{ item1 }}
                  </span>
                </template>
                <span v-else>
                  {{ $t(item.key) }}：{{ item.data }}
                </span>
              </p>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="new-detail">
              <p>{{ $t('updatedInformation') }}</p>
              <p
                v-for="item in afterDetail"
                :key="item.key"
                :class="{active:item.isDifferent}">
                <template v-if="fileFields.includes(item.key)">
                  <span>{{ $t(item.key) }}：</span>
                  <span
                    style="display: block"
                    v-for="(item1,index) in item.data"
                    :key="item.key + index">
                    {{ index + 1 }}.{{ item1 }}
                  </span>
                </template>
                <span v-else>
                  {{ $t(item.key) }}：{{ item.data }}
                </span>
              </p>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'UpdateHistory',
  props: {
    company: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      dialogVisible: false,
      histories: [],
      currentHistory: {},
      beforeDetail: [],
      afterDetail: [],
      currentRowKey: '',
      fileFields: [
        'businessIncorporationDocument',
        'authorizationLetter',
        'operatorLicences',
        'craftOperatorLicences',
        'bdnLogo',
        'otherDocument'
      ]
    }
  },
  created () {
    this.getHistory()
  },
  mounted () {
    this.dialogVisible = true
  },
  methods: {
    getHistory () {
      /**
       * 获取企业更新的历史记录
       */
      this.$request.post({
        url: this.$apis.queryAuditHistory,
        data: {
          data: { tableId: this.company.id },
          size: -1
        }
      }).then(res => {
        if (res?.code === 1000) {
          this.histories = res.data.records || []
          if (this.histories.length) {
            const currentRow = this.histories[0]
            this.rowClickHandler(currentRow)
            this.currentRowKey = currentRow.id
          }
        }
      })
    },
    rowClickHandler (company) {
      /**
       * 显示修改详情
       */
      const displayedFields = [
        'businessIncorporationNumber',
        'companyName',
        'abbreviation',
        'incorporationDate',
        'incorporationCountry',
        'streetName',
        'buildingName',
        'unitNumber',
        'postal',
        'companyType',
        // 'contactPerson',
        // 'email',
        // 'phone',
        // 'designation',
        'businessIncorporationDocument',
        'bdnLogo',
        'fax',
        'authorizationLetter',
        'operatorLicences',
        'craftOperatorLicences',
        'otherDocument'
      ]
      const beforeDetail = JSON.parse(company.beforeDetail)
      const afterDetail = JSON.parse(company.afterDetail)
      const compare = key => {
        if (this.fileFields.includes(key)) {
          const beforeDetailFileIds = (beforeDetail[key] ?? []).map(item => item.id).sort()
          const afterDetailFileIds = (afterDetail[key] ?? []).map(item => item.id).sort()
          return !this.$_.isEqual(beforeDetailFileIds, afterDetailFileIds)
        }
        return beforeDetail[key] !== afterDetail[key]
      }
      this.beforeDetail = displayedFields.map(key => {
        if (this.fileFields.includes((key))) {
          const data = beforeDetail[key] ?? []
          return {
            key,
            data: data.map(item => item.originalName),
            isDifferent: compare(key)
          }
        }
        return {
          key,
          data: beforeDetail[key],
          isDifferent: compare(key)
        }
      })
      this.afterDetail = displayedFields.map(key => {
        if (this.fileFields.includes((key))) {
          const data = afterDetail[key] ?? []
          return {
            key,
            data: data.map(item => item.originalName),
            isDifferent: compare(key)
          }
        }
        return {
          key,
          data: afterDetail[key],
          isDifferent: compare(key)
        }
      })
    },
    formatApprove (value) {
      /**
       * status与后端约定,0:初始状态,1:审批中,2:拒绝,3:通过
       */
      const map = {
        1: 'EXECUTE',
        2: 'rejected',
        3: 'agreed'
      }
      return this.$t(map[value])
    }
  },
  filters: {
    formatModifyUser (userStr) {
      const { firstName, lastName } = JSON.parse(userStr)
      return firstName + ' ' + lastName
    }
  }
}
</script>

<style lang="scss" scoped>
.update-history-dialog {
  .content-container {
    padding: 0 20px;
    display: flex;
    height: calc(100vh - 120px);
    overflow-y: hidden;
    .detail, .left-table{
      height: 100%;
      max-width: 50%;
      overflow-y: auto;
      overflow-x: hidden;
    }

    .detail {

      div {
        padding: 0 20px;

        p {
          margin: 8px 0;
          padding: 5px;
        }

        p:first-child {
          padding: 10px;
          border-radius: 10px;
          color: white;
          background-color: $primary-color;
        }

        .active {
          border-radius: 10px;
          border: 1px dashed #E6A23C;
        }
      }

      @mixin common {
        padding: 20px;
        border-radius: 10px;
        background-color: #F2F6FC;
      }

      .old-detail {
        @include common;
      }

      .new-detail {
        @include common;

        p:first-child {
          background-color: #E6A23C;
        }
      }
    }

    ::-webkit-scrollbar {
      width: 0 !important;
    }
  }
}
</style>
