var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    _vm._g(
      {
        attrs: {
          fullscreen: "",
          visible: _vm.dialogVisible,
          width: "80%",
          "custom-class": "update-history-dialog",
        },
        on: {
          "update:visible": function ($event) {
            _vm.dialogVisible = $event
          },
        },
      },
      _vm.$listeners
    ),
    [
      _c("base-title", {
        attrs: {
          slot: "title",
          text: `${_vm.$t("updateHistory")} [${_vm.company.companyName}]`,
        },
        slot: "title",
      }),
      _c(
        "div",
        { staticClass: "content-container" },
        [
          _c(
            "el-table",
            {
              directives: [{ name: "no-border", rawName: "v-no-border" }],
              staticClass: "left-table",
              staticStyle: { "margin-right": "20px" },
              attrs: {
                data: _vm.histories,
                stripe: "",
                "row-key": "id",
                "highlight-current-row": "",
                size: "small",
                "current-row-key": _vm.currentRowKey,
              },
              on: { "row-click": _vm.rowClickHandler },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: _vm.$t("serialNum"),
                  width: "50",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "updateTime",
                  align: "center",
                  label: _vm.$t("updateTime"),
                },
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: _vm.$t("updatedBy") },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatModifyUser")(scope.row.modifyUser)
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: _vm.$t("auditStatus"), align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-tag",
                          {
                            attrs: {
                              type:
                                scope.row.status === 1
                                  ? "warning"
                                  : scope.row.status === 2
                                  ? "danger"
                                  : "success",
                              size: "mini",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.formatApprove(scope.row.status)) +
                                " "
                            ),
                          ]
                        ),
                        _c("span"),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "comment",
                  align: "center",
                  label: _vm.$t("auditInstruction"),
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.histories.length,
                  expression: "histories.length",
                },
              ],
              staticClass: "detail",
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c(
                      "div",
                      { staticClass: "old-detail" },
                      [
                        _c("p", [_vm._v(_vm._s(_vm.$t("preUpdateInfo")))]),
                        _vm._l(_vm.beforeDetail, function (item) {
                          return _c(
                            "p",
                            {
                              key: item.key,
                              class: { active: item.isDifferent },
                            },
                            [
                              _vm.fileFields.includes(item.key)
                                ? [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t(item.key)) + "："),
                                    ]),
                                    _vm._l(item.data, function (item1, index) {
                                      return _c(
                                        "span",
                                        {
                                          key: item.key + index,
                                          staticStyle: { display: "block" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(index + 1) +
                                              "." +
                                              _vm._s(item1) +
                                              " "
                                          ),
                                        ]
                                      )
                                    }),
                                  ]
                                : _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t(item.key)) +
                                        "：" +
                                        _vm._s(item.data) +
                                        " "
                                    ),
                                  ]),
                            ],
                            2
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c(
                      "div",
                      { staticClass: "new-detail" },
                      [
                        _c("p", [_vm._v(_vm._s(_vm.$t("updatedInformation")))]),
                        _vm._l(_vm.afterDetail, function (item) {
                          return _c(
                            "p",
                            {
                              key: item.key,
                              class: { active: item.isDifferent },
                            },
                            [
                              _vm.fileFields.includes(item.key)
                                ? [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t(item.key)) + "："),
                                    ]),
                                    _vm._l(item.data, function (item1, index) {
                                      return _c(
                                        "span",
                                        {
                                          key: item.key + index,
                                          staticStyle: { display: "block" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(index + 1) +
                                              "." +
                                              _vm._s(item1) +
                                              " "
                                          ),
                                        ]
                                      )
                                    }),
                                  ]
                                : _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t(item.key)) +
                                        "：" +
                                        _vm._s(item.data) +
                                        " "
                                    ),
                                  ]),
                            ],
                            2
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }